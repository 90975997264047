$font_family: Montserrat, Arial;

// Colors
$red_error: #ff0005 !default;
$red: #ff0005;
$black: #010202 !default;
$green: #beef00;
$white: #fff;
$gray: #e5e5e5;
$lightGray: #d5dde0;
$bg_gray: rgba(229, 229, 229, 0.5);

// header
$header_title_font_family: $font_family;
$header_title_font_size: 16px;
$header_title_line_height: 22px;
$header_title_font_weight: 700;
$header_title_color: $white;

// Product
$product_title_font_size: 14px;
$product_title_color: $black;
$product_title_font_weight: 400;
$product_title_line_height: 17px;

$product_price_font_size: 16px;
$product_price_color: $black;
$product_price_font_weight: 600;
$product_price_line_height: 19.5px;

$product_old_price_font_size: 14px;
$product_old_price_color: $black;
$product_old_price_font_weight: 300;
$product_old_price_line_height: 17px;
$product_old_price_text_decoration: line-through;

$product_popup_z_index_desktop: 15;
$product_popup_z_index_mobile: 15;
$controls_z_index_desktop: 20;
$controls_z_index_mobile: 3;

//Likes counter - Mobile
$likes_color: $white;
$likes_font_size: 14px;
$likes_font_family: $font_family;
$likes_line_height: 17.07px;
$likes_font_weight: 600;

//Live tag
$live_tag_font_size: 10px;
$live_tag_line_height: 14px;

$video_controls_bg_color: rgba(0, 0, 0, 0.25);
$video_controls_border_radius: 6px;
$volumen_bar_height: 5px;
$volumen_bar_thumb_size: 10px;
