.MessageOptionsWrapper {
	border-radius: 6px;
	z-index: 2;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-color: #fff;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		> li {
			display: grid;
			padding: 5px 8px;
			> button {
				text-align: left;
				cursor: pointer;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				padding: 7px 12px 7px 8px;
				margin: 0;
				border: 0;
				background: none;

				display: grid;
				grid-template-columns: 1fr auto;
				gap: 8px;
				border-radius: 8px;
				.Text {
					color: #000;
				}
				.IconWrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					color: #000;
				}

				&:hover {
					background-color: #f7f8f9;
				}
			}
		}
	}
}
