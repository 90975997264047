@import "../../styles/variables.scss";

$transition-duration: 0.1s;

.popup__wrapper {
	display: flex;
	position: relative;
	width: 80px;
	align-items: center;
	transition: width $transition-duration ease-in-out;
	&:hover {
		width: 277px;
		.product__card {
			height: 100px;
			width: 277px;
		}
		.img__container {
			height: 90px;
			width: 90px;
			img {
				height: 90px;
				width: 90px;
			}
		}
		.card_text {
			width: 100%;
		}
	}

	.product__card {
		background-color: #fff;
		display: flex;
		flex-direction: row;
		width: 80px;
		height: 80px;
		border-radius: 6px;
		padding: 6.32px;
		align-items: center;
		cursor: pointer;
		gap: 12px;
		transition: height $transition-duration,
			width $transition-duration ease-in-out;
		justify-content: flex-start;
	}

	.img__container {
		height: 67.69px;
		display: flex;
		border-radius: 6px;
		align-items: center;
		justify-content: center;
		transition: height $transition-duration, width $transition-duration,
			border-radius $transition-duration ease-in-out;

		img {
			border-radius: 6px;
			height: 67.69px;
			width: 67.69px;
			object-fit: contain;
			transition: height $transition-duration, width $transition-duration,
				border-radius $transition-duration ease-in-out;
		}
	}

	.card_text {
		width: 0%;
		overflow: hidden;
		transition: width $transition-duration ease-in-out;
		display: inline-block;
		display: flex;
		flex-direction: column;
		.labels {
			display: flex;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			div {
				padding: 0px 2px;
				border-radius: 4px;
			}
		}
		.title {
			width: 161px;
			font-family: $font_family;
			font-size: $product_title_font_size;
			color: $product_title_color;
			font-weight: $product_title_font_weight;
			line-height: $product_title_line_height;
			margin-bottom: 4px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			word-break: normal;
		}
		.prices_wrapper {
			display: flex;
			justify-content: start;

			.prices_group {
				text-align: left;

				.oldPrice,
				.price {
					font-family: $font_family;
					display: block;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					word-break: normal;
				}

				.price {
					font-size: $product_price_font_size;
					color: $product_price_color;
					font-weight: $product_price_font_weight;
					line-height: $product_price_line_height;
				}

				.oldPrice {
					font-size: $product_old_price_font_size;
					color: $product_old_price_color;
					font-weight: $product_old_price_font_weight;
					line-height: $product_old_price_line_height;
					text-decoration: $product_old_price_text_decoration;
				}
			}
		}
	}
}
