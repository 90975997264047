.InputWrapper {
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	gap: 8px;
	.TextInput {
		box-sizing: border-box;
		padding: 8px;
		border-radius: 8px;
		border: 1px solid #d5dde0;
		background: #fff;

		width: 100%;
		max-width: 100%;
		font-size: 16px;
		line-height: 1.5;
		font-style: normal;
		font-weight: 500;
		overflow-y: auto;
		overflow-x: hidden;
		resize: none;
		max-height: 108px;
		min-height: 16px;
		transition: 0.2s ease-in;

		word-wrap: break-word;
		white-space: pre-wrap;
		overflow-wrap: break-word;
		word-break: break-word;
	}
}

.Placeholder {
	position: absolute;
	top: 10px;
	left: 10px;
	color: #abafb1;
	pointer-events: none;
}

.CharCounter {
	border-radius: 4px;
	padding: 0px 8px;
	background-color: #fff;
	position: absolute;
	top: -7px; // Adjust as needed
	right: 12px; // Adjust as needed
	font-size: 12px; // Adjust as needed
	color: #888; // Adjust as needed
	transition: 0.2s ease-in;
	display: none;
	@media (min-width: 982px) {
		display: block;
	}
}

.alwaysShow {
	display: block !important; /* Force show */
}

.CharCounterOverLimit {
	color: white;
	border: 1px solid #f00;
	background: #f00;
}

.EmojiPickerWrapper {
	position: absolute;
	bottom: 40px; // Adjust as needed
	right: 0; // Adjust as needed
	z-index: 1; // Ensure it appears above other elements
}
.ChatInputControls {
	box-sizing: border-box;
	width: 100%;
	display: grid;
	grid-template-columns: 10% 30% 1fr;
	&.NoLikeButton {
		grid-template-columns: 20% 1fr;
	}
	gap: 8px;
	.Button {
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		cursor: pointer;
		background: none;
		border: none;

		border-radius: 4px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		.TextWrapper {
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
		}
		.IconWrapper {
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&:disabled {
			cursor: not-allowed;
			background-color: #d5dde0 !important;
			color: #fff !important;
		}
	}
	.LikeButton {
		padding: 4px;
		.IconWrapper {
			font-size: 21px;
		}
		.Text {
			text-align: center;
			font-family: Montserrat;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
		}
	}
	.EmojiToggleButton {
		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 24px;
		color: #d5dde0;
		padding: 0;
		border-radius: 4px;
		background: var(--background-surface, #f7f8f9);
	}
}

@media (max-width: 981px) {
	.TextInput {
		background: rgba(0, 0, 0, 0.2) !important;
		color: #fff;
		backdrop-filter: blur(60px);
		border-color: transparent !important;
	}

	.CharCounter {
		top: auto;
		bottom: -8px;
	}

	.ChatInputControls {
		display: none;
	}

	.MobileInputControls {
		z-index: 4;
		position: absolute;
		right: -58px;
		bottom: -2px;
		button {
			border: none;
			background-color: #0000004d;
			border-radius: 6px;
			height: 48px;
			width: 48px;
			font-size: 14px;
		}
	}
}
