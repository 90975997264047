.ShoppingFormWrapper {
	z-index: 10;
	position: absolute;
	background-color: #01020228;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;

	.FormWrapper {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		width: 100%;
		background: #fff;
		padding: 24px 16px 16px 16px;
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		font-family: "Montserrat", sans-serif;
		form {
			display: flex;
			flex-direction: column;
			gap: 16px;
			.Top {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.Title {
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 18px;
			}
		}

		.TextInput {
			font-size: 16px;
			height: 40px;
			width: 100%;
			border-radius: 8px;
			background: #fff;
			padding: 12px 12px 12px 32px;
			font-style: normal;
			font-weight: 400;
			border: 1px solid #d0d0d0;
			&.WithError {
				border-color: red;
			}
			&::placeholder {
				color: #d0d0d0;
			}
		}
	}
}

.ShoppingButton {
	background: var(--yellow-200, #ffde07);
	border: none;
	width: 100%;
	height: 40px;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}

.Disabled {
	background: #d5dde0;
	border: none;
	width: 100%;
	height: 40px;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	pointer-events: none;
}

.DisclaimerWrapper {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	label {
		margin: 0;
		font-size: 10px;
		font-style: normal;
		span {
			font-weight: 400;
		}
		a {
			color: #000;
			font-weight: 700;
			text-decoration-line: underline;
		}
		line-height: 14px;
	}
}

.formSlideIn {
	animation: slideInUp 0.2s ease-out forwards;
}

.formSlideOut {
	animation: slideOutDown 0.2s ease-in forwards;
}

@keyframes showUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes showOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.backdropShow {
	animation: showUp 0.2s ease-out;
}

.backdropOut {
	animation: showOut 0.2s ease-in;
}
