$responsive-gap: 1rem;

$video-min-width: clamp(200px, 25vw, 400px);
$video-height-portrait: 1fr;

.responsiveGrid {
	display: grid;
	// gap: $responsive-gap;
	width: 100%;
	height: 100%;
	place-items: center;

	grid-template-columns: repeat(auto-fit, minmax($video-min-width, 1fr));
	grid-auto-rows: auto;

	@media (orientation: portrait) {
		grid-template-columns: 1fr;
		grid-auto-rows: $video-height-portrait;
		place-content: center;
	}
}

.videoSlot {
	background-color: black;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
