.message_popup__content {
	background-color: #010202;
	border-radius: 11px;
	width: 480px;
	height: 65px;
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	grid-gap: 10px;
	align-items: center;

	.message_popup__content__title {
		font-family: Montserrat, Arial;
		color: #fff;
		font-size: 20px;
		line-height: 28px;
		font-weight: 600;
	}

	.message_popup__content__text {
		font-family: Montserrat, Arial;
		color: #fff;
		font-size: 16px;
		line-height: 24px;
	}

	.message_popup__content__image,
	.message_popup__content__icon {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.message_popup__content__image {
		.storelogo {
			width: 50px;
			height: 50px;
			border-radius: 50%;
		}
	}
	@media (max-width: 981px) {
		width: 350px;

		.message_popup__content__title {
			font-size: 15px;
		}

		.message_popup__content__text {
			font-size: 12px;
		}
	}
}
