.LoginFormWrapper {
	z-index: 10;
	position: absolute;
	background-color: #01020228;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
}

.FormWrapper {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	width: 100%;
	background: #fff;
	padding: 16px;
	box-sizing: border-box;
	position: absolute;
	bottom: 0;

	form {
		display: flex;
		flex-direction: column;
		gap: 16px;
		.Title {
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px;
		}
	}
	.InputWrapper {
		position: relative;
		.CharCounter {
			position: absolute;
			bottom: -8px;
			right: 8px;

			border-radius: 4px;
			padding: 0px 6px;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
		}
	}
	.TextInput {
		font-size: 16px;
		height: 40px;
		width: 100%;
		border-radius: 8px;
		background: #fff;
		padding: 12px 24px;
		font-style: normal;
		font-weight: 400;
		border: 1px solid #d0d0d0;
		&.WithError {
			border-color: red;
		}
		&::placeholder {
			color: #d0d0d0;
		}
	}

	.SubmitButton {
		width: 100%;
		height: 40px;
	}

	.CloseButton {
		position: absolute;
		top: 16px;
		right: 16px;
		padding: 0;
		font-size: 16px;
		border: none;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}

.DisclaimerWrapper {
	display: flex;
	align-items: flex-start;
	gap: 8px;
	label {
		margin: 0;
		font-size: 10px;
		font-style: normal;
		span {
			font-weight: 400;
		}
		a {
			color: #000;
			font-weight: 700;
			text-decoration-line: underline;
		}
		.Extra {
			color: #000;
			font-weight: 700;
			text-decoration-line: underline;
		}
		line-height: 14px;
	}
}

.ButtonTheme {
	border: none;
	border-radius: 8px;
	background: var(--yellow-200, #ffde07);
	height: 33px;
}

@keyframes slideInUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideOutDown {
	from {
		transform: translateY(0);
		opacity: 1;
	}
	to {
		transform: translateY(100%);
		opacity: 0;
	}
}

.formSlideIn {
	animation: slideInUp 0.2s ease-out forwards;
}

.formSlideOut {
	animation: slideOutDown 0.2s ease-in forwards;
}

@keyframes showUp {
	from {
		transform: translateY(100%);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes showOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.backdropShow {
	animation: showUp 0.2s ease-out;
}

.backdropOut {
	animation: showOut 0.2s ease-in;
}

@media (max-width: 981px) {
	.LoginFormWrapper {
		.FormWrapper {
			padding-bottom: 20px;
		}
	}
}
