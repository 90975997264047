.ShoppingCartWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: calc(100% - 42px);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	box-sizing: border-box;
	padding: 8px;
	font-family: "Montserrat", sans-serif;
	gap: 8px;

	.emptyContainer {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		align-content: center;
		align-items: center;

		.infoText {
			color: #d5dde0;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
		}
	}
	.Items {
		display: flex;
		flex-direction: column;
		gap: 8px;
		max-height: 85%;
		overflow-x: hidden;
		overflow-y: auto;
		align-items: center;
	}

	.Bottom {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.Subtotal {
			box-sizing: border-box;
			background-color: #f7f8f9;
			border-radius: 8px;
			width: 100%;
			height: 30px;
			font-size: 12px;
			font-weight: 300;
			display: flex;
			justify-content: space-between;
			padding: 0 12px;
			align-items: center;
			.subtotalPrice {
				font-weight: 600;
			}
		}

		button {
			background: var(--yellow-200, #ffde07);
			border: none;
			width: 100%;
			height: 40px;
			border-radius: 8px;
			display: flex;
			flex-direction: row;
			column-gap: 8px;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
		}
	}
	.FiraCartItemWrapper {
		border-bottom: 1px solid #d5dde0;
		width: 100%;
		min-height: 82px;
		display: grid;
		grid-template-rows: 34px 24px;
		grid-template-columns: 65px auto;
		column-gap: 8px;
		grid-template-areas:
			"image info"
			"image extra"
			"image actions"
			"control control";
		padding-top: 8px;
		#gridImage {
			grid-area: image;
			.imageContainer {
				width: 100%;
				height: 100%;
				border-radius: 6px;
				background-color: #e5e5e6;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		#extraInfo {
			grid-area: extra;
			.variationText {
				font-size: 12px;
				font-weight: 500;
				color: #858585;
			}
		}

		#quantityControl {
			grid-area: control;
			margin-top: 10px;
			margin-bottom: 10px;
			.inputWrapper {
				width: 100%;
				display: grid;
				grid-template-columns: auto 1fr auto;
				gap: 10px;
				> button {
					border-radius: 4px;
					border: 1px solid #d0d0d0;
					background: #fff;
					width: 43px;
					height: 35px;
				}
				> div {
					border-radius: 4px;
					border: 1px solid var(--Opacidad, #d0d0d0);
					background: #f5f5f5;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		#gridInfo {
			grid-area: info;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 16px;
			align-items: flex-start;
			.infoTitle {
				font-size: 14px;
				font-weight: 400;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.deleteIcon {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				cursor: pointer;
			}
		}

		#gridActions {
			grid-area: actions;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			gap: 5px;
			align-items: center;
			.price {
				font-size: 14px;
				font-weight: 600;
				color: #f16063;
				&.discount {
					color: #929292 !important;
					font-size: 10px;
					font-weight: 300;
					text-decoration-line: line-through;
				}
			}
		}
	}
}

.InfoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: "Montserrat";
	color: #010202;
	gap: 16px;
}
