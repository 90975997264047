@import "../../styles/variables.scss";

.carousel__element {
	display: flex;
	position: relative;
	justify-self: center;
	align-items: center;

	.product__card {
		display: flex;
		width: 277px;
		height: 100px;
		border-radius: 6px;
		padding: 0 7px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.img__container {
		height: 88px;
		display: flex;
		align-items: center;

		img {
			border-radius: 6px;
			width: 88px;
			height: 100%;
			object-fit: contain;
		}
	}

	.copy__container {
		display: grid;
		width: 100%;
		padding-left: 12px;

		> .TagsWrapper {
			position: absolute;
			top: 5px;
			display: flex;
			gap: 6px;
			> .Label {
				border-radius: 4px;
				height: 13px;
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 96px;
				padding: 0px 3px;
			}
		}

		.title_wrapper {
			display: flex;
			justify-content: start;
			align-items: center;
			margin-top: 6px;

			.title {
				font-family: $font_family;
				font-size: $product_title_font_size;
				color: $product_title_color;
				font-weight: $product_title_font_weight;
				line-height: $product_title_line_height;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				word-break: normal;
				overflow-wrap: anywhere;
			}
		}

		.prices_wrapper {
			display: flex;
			justify-content: start;

			.prices_group {
				text-align: left;

				.oldPrice,
				.price {
					font-family: $font_family;
					display: block;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					text-overflow: ellipsis;
					word-break: normal;
					overflow-wrap: anywhere;
				}

				.price {
					font-size: $product_price_font_size;
					color: $product_price_color;
					font-weight: $product_price_font_weight;
					line-height: $product_price_line_height;
				}

				.oldPrice {
					font-size: $product_old_price_font_size;
					color: $product_old_price_color;
					font-weight: $product_old_price_font_weight;
					line-height: $product_old_price_line_height;
					text-decoration: $product_old_price_text_decoration;
				}
			}
		}
	}

	&:hover {
		text-decoration: none;
	}
}
