@import "~react-image-gallery/styles/scss/image-gallery.scss";

.ComponentWrapper {
	background-color: #fff;
	border-radius: 6px;
	position: relative;
	font-family: "Montserrat";
	max-height: 500px;
	max-width: 500px;

	@media (max-width: 981px) {
		width: 100%;
	}

	@media (max-height: 500px) {
		.ComponentWrapper {
			max-width: 600px;
		}
	}

	.ContentWrapper {
		padding: 16px 16px 88px 16px;
		height: 100%;
		width: 100%;
		overflow-y: auto;
		max-height: 500px;
		max-width: 500px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: 22px;
	}

	.HeaderWrapper {
		display: grid;
		grid-template-columns: auto 1fr auto;
		align-items: flex-start;
		gap: 17px;
		.Preview {
			width: 143px;
			height: 143px;
			background-color: #d9d9d9;
			border-radius: 4px;
			* {
				height: 143px;
			}

			@media (max-width: 981px) {
				width: 90px;
				height: 90px;
				* {
					height: 90px;
				}
			}
		}
		.Details {
			display: grid;
			grid-template-rows: auto auto auto 1fr;
			.Labels {
				display: flex;
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				gap: 6px;
				div {
					padding: 0px 2px;
					border-radius: 4px;
				}
			}
			.Name {
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 9px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.PriceWrapper {
				display: flex;
				gap: 10px;
				margin-bottom: 16px;
				> Span {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
				.Dash {
					font-weight: 300;
					text-decoration-line: line-through;
				}
			}
		}
	}
	.Description,
	.DescriptionAlt {
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 5;
		overflow: hidden;
		text-overflow: ellipsis;
		* {
			margin: 0;
		}
	}
	.DescriptionAlt {
		display: none;
	}
	@media (max-width: 981px) {
		.Description {
			display: none;
		}
		.DescriptionAlt {
			display: block;
		}
	}

	.OptionWrapper {
		display: flex;
		flex-direction: column;
		gap: 13px;
		.Title {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
		}
		.OptionList {
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
			button {
				cursor: pointer;
				border-radius: 4px;
				border: 1px solid #d5dde0;
				background: #fff;
				padding: 11px 17px;

				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.FooterWrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 10px;
		background-color: #fff;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		button {
			height: 40px;
			border-radius: 4px;
			flex: 2;
			border: none;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			transition: 0.5s ease-in-out;

			&:disabled {
				background-color: #d5dde0 !important;
				color: #fff !important;
			}
		}
	}
}

.BackButton {
	font-size: 18px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
}

.CloseButton {
	font-size: 18px;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
}

.BigGalleryWrapper {
	padding: 0px 25px 16px 25px;
}

.GalleryHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 16px 0px 16px;
}
.SliderWrapper {
	width: 100%;
	height: 100%;
	gap: 20px;
	position: relative;
	button {
		z-index: 10;
		margin: 0px;
		padding: 0px;
		height: 32px !important;
		width: 32px;
		display: flex;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		background-color: #fff;
		border: none;
		position: absolute;
		* {
			height: 100% !important;
		}
		&.Leading,
		&.Trailing {
			top: 50%;
			transform: translateY(-50%);
		}
		&.Leading {
			left: -20px;
		}
		&.Trailing {
			right: -20px;
		}
	}
	.GalleryWrapper {
		width: 100%;
		height: 100%;
		.BulletList {
			margin-top: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			height: 8px;
			.Bullet {
				width: 8px;
				height: 8px;
				border-radius: 4px;
			}
		}
	}
}

.ControlWrapper {
	width: 100px;
	height: 40px;
	text-align: center;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	background-color: #fff;
	color: #000;
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;
	.IconWrapper {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px 5px;
	}
}
