body {
	margin: 0;
}

#balloon-container {
	height: 100vh;
	padding: 1em;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
}
.balloon {
	cursor: auto;
	bottom: 0px;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 8;
	overflow: overlay;
}
.balloon:before {
	left: 15px;
	transform: rotate(-45deg);
	transform-origin: 0 100%;
}
.balloon::after {
	left: 0;
	transform: rotate(45deg);
	transform-origin: 100% 100%;
}
.balloon::before,
.balloon::after {
	content: "";
	position: absolute;
	top: 0;
	width: 15px;
	height: 23px;
	border-radius: 50px 50px 0 0;
	background: #f16063;
}

.image-balloon {
	&::before,
	&:after {
		content: none;
	}
}

@keyframes float {
	from {
		transform: translateY(100vh);
		opacity: 1;
	}
	to {
		transform: translateY(-300vh);
		opacity: 0;
	}
}
