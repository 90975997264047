.LoadingMessageWrapper {
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 14px;
	width: 100%;
	height: 100%;
	height: -webkit-fill-available;
	background-color: black;
	> p {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		margin: 0;
	}
}
