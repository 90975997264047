@import "../../styles/variables.scss";

#videoControls {
	display: grid;
	grid-template-columns: max-content 1fr max-content max-content max-content max-content;
	&.live-video,
	&.preview-video {
		grid-template-columns: 1fr max-content max-content max-content max-content;
	}
	gap: 16px;
	position: absolute;
	padding: 0 16px 0 24px;
	width: 100%;
	border-radius: 6px;
	bottom: 15px;
	z-index: $controls_z_index_desktop;

	.cart-button img {
		color: #fff;
	}

	.volumen-control {
		display: flex;
		align-items: center;

		#progress {
			width: 100%;
			height: 8px;
			border: none;
		}
	}

	.play-button {
		order: 1;
	}

	.volumen-control-wrapper {
		order: 2;
	}

	.volume-button {
		order: 3;
	}

	.fullscreen-button {
		order: 4;
	}

	.pip-button {
		order: 5;
	}

	.share-button {
		order: 7;
	}

	.cart-button {
		order: 6;
	}

	.volumen-control-wrapper {
		display: flex;
		align-items: center;
		background-color: $video_controls_bg_color;
		border-radius: $video_controls_border_radius;
		padding: 0 16px;

		.volumen-control {
			width: 100%;
		}

		#progress_bar {
			--scrollbar-background: #ccc;
			-webkit-appearance: none;
			height: $volumen_bar_height;
			width: 100%;
			background-repeat: no-repeat;
			cursor: pointer;
		}

		/* Input Thumb */
		#progress_bar::-webkit-slider-thumb {
			-webkit-appearance: none;
			height: $volumen_bar_thumb_size;
			width: $volumen_bar_thumb_size;
			border-radius: 50%;
			background: var(--scrollbar-background);
			cursor: ew-resize;
			box-shadow: 0 0 2px 0 #555;
			transition: background 0.3s ease-in-out;
		}

		#progress_bar::-moz-range-thumb {
			-webkit-appearance: none;
			height: $volumen_bar_thumb_size;
			width: $volumen_bar_thumb_size;
			border-radius: 50%;
			background: var(--scrollbar-background);
			cursor: ew-resize;
			box-shadow: 0 0 2px 0 #555;
			transition: background 0.3s ease-in-out;
		}

		#progress_bar::-ms-thumb {
			-webkit-appearance: none;
			height: $volumen_bar_thumb_size;
			width: $volumen_bar_thumb_size;
			border-radius: 50%;
			background: var(--scrollbar-background);
			cursor: ew-resize;
			box-shadow: 0 0 2px 0 #555;
			transition: background 0.3s ease-in-out;
		}

		#progress_bar::-webkit-slider-thumb:hover {
			background: var(--scrollbar-background);
		}

		#progress_bar::-moz-range-thumb:hover {
			background: var(--scrollbar-background);
		}

		#progress_bar::-ms-thumb:hover {
			background: var(--scrollbar-background);
		}

		/* Input Track */
		#progress_bar::-webkit-slider-runnable-track {
			-webkit-appearance: none;
			box-shadow: none;
			border: none;
			background: transparent;
		}

		#progress_bar::-moz-range-track {
			-webkit-appearance: none;
			box-shadow: none;
			border: none;
			background: transparent;
		}

		#progress_bar::-ms-track {
			-webkit-appearance: none;
			box-shadow: none;
			border: none;
			background: transparent;
		}

		#progress_bar::-ms-thumb .pointer {
			cursor: pointer;
		}
	}

	.video-control {
		cursor: pointer;
		background-color: $video_controls_bg_color;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: $video_controls_border_radius;
		backdrop-filter: blur(2px);

		&:hover {
			opacity: 1;
		}

		.copy_dialog {
			padding: 5px 10px 5px 10px;
			background-color: rgba(1, 2, 2, 0.3);
			position: absolute;
			border-radius: 6px;
			bottom: 50px;
			right: 0;
			white-space: nowrap;

			span {
				font-family: Montserrat;
				font-weight: 600;
				font-size: 14px;
				color: #fff;
			}

			.right_point {
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 10px solid rgba(1, 2, 2, 0.3);
				position: absolute;
				top: 34px;
				right: 15px;
				transform: rotate(0deg);
			}
		}
	}

	.heart-container {
		display: grid;
		justify-content: center;
		height: 48px;
		width: 48px;
		padding-top: 4px;
		padding-bottom: 4px;

		.likes-counter {
			display: flex;
			justify-content: center;
			color: $likes_color;
			font-family: $likes_font_family;
			font-weight: $likes_font_weight;
			font-size: $likes_font_size;
			line-height: $likes_line_height;
		}
	}

	@media screen and (max-width: 981px) {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		bottom: 50px;
		right: 15px;
		z-index: 5;
		gap: 15px;
		width: auto;

		.share-button {
			order: 2;
		}

		.pip-button {
			order: 3;
		}

		.chat-button {
			order: 4;
		}

		.volume-button {
			order: 5;
		}

		.like-button {
			order: 6;
		}

		.video-control {
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 6px;
			margin: 0;
			padding: 8px;
			backdrop-filter: blur(10px);

			.copy_dialog {
				right: 4rem;
				bottom: initial;
				top: 0;

				.right_point {
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 20px solid rgba(1, 2, 2, 0.3);
					top: 8px;
					right: -16px;
					transform: rotate(-90deg);
				}
			}
		}

		.video-control-cart {
			position: relative;
		}

		.pip-button {
			position: relative;
		}
	}
}

.cart-indicator {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	left: 30px;
	top: 6px;
}
