.ListWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	margin-bottom: 8px;
	.InfiniteListWrapper {
		width: 100%;
	}
	.ScrollBackButton {
		position: absolute;
		font-feature-settings: "clig" off, "liga" off;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%);
		border: 0;
		border-radius: 8px;
		text-transform: capitalize;
	}
}

.EmptyListWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	gap: 8px;
	.IconWrapper {
		font-size: 100px;
		color: #e5e5e6;
	}
	.TextWrapper {
		display: flex;
		flex-direction: column;
	}
	.Text {
		max-width: 200px;
		color: #0e1621;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		@media (max-width: 981px) {
			color: #a4a4a4;
		}
	}
	.SubText {
		max-width: 200px;
		text-align: center;
		color: #8a898e;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}
}

.hideScrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
