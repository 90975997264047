@import "./variables.scss";

.live-badge {
	background-color: $red_error;
}

.style-button-primary {
	color: $black;
	border-radius: 6px;
	border: 2px solid #fff;
	text-align: center;
	padding: 5px 10px;

	span {
		margin: auto;
		font-family: $font_family;
		font-weight: 600;
		font-size: 14px;
	}

	&:hover {
		opacity: 0.8;
	}
}

.h3-header {
	font-family: $font_family;
	font-size: 17px;
	font-weight: 700;
	line-height: 18px;
	color: $black;
}

.body-text {
	font-family: $font_family;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	color: $black;
	margin-bottom: 0.5px;

	&-dark {
		font-weight: 700;
	}
}

.body-text-smaller {
	font-family: $font_family;
	font-weight: 500;
	font-size: 9px;
	line-height: 14px;
	color: $black;
	margin-bottom: 0.5px;

	&-dark {
		font-weight: 700;
		font-family: $font_family;
		font-size: 9px;
		line-height: 14px;
		color: $black;
		margin-bottom: 0.5px;
	}
}

.bg-white {
	background-color: $white;
}
