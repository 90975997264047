@import "../../styles/variables.scss";

.BottomSheetWrapper {
	display: none;
	width: 100%;
	height: 431px;
	background-color: #fff;
	position: absolute;
	left: 0;
	bottom: 100%;
	z-index: $product_popup_z_index_mobile;
	border-radius: 16px 16px 0px 0px;
	overflow: hidden;
	transition: 0.15s ease-in;
	&.SheetOpen {
		bottom: 0;
	}
	.Top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 16px;
		border-bottom: 1px solid #d5dde0;
	}
	.Title {
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
	}
	.BottomSheet {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		.CloseIcon {
			position: absolute;
			right: 20px;
			top: 12px;
			z-index: 9999;
			cursor: pointer;
			svg {
				height: 24px;
				font-size: 24px;
			}
		}
	}
}
