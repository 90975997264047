.ModuleTabWrapper {
	width: 100%;
	height: 42px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	box-sizing: border-box;
	border-bottom: 1px solid #d5dde0;
	position: relative;
	&.singleColumn {
		grid-template-columns: 1fr;
	}
	&.multipleColumn {
		grid-template-columns: 1fr 1fr;
	}
	.TabItem {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.indicatorCart {
		width: 100%;
		height: 5px;
		background-color: var(--yellow-200, #ffde07);
		border-radius: 6px;
		position: absolute;
		bottom: 0;
	}
	.indicator,
	.indicatorRight {
		width: 50%;
		height: 5px;
		background-color: var(--yellow-200, #ffde07);
		border-radius: 6px;
		position: absolute;
		bottom: 0;
	}
	.indicator {
		left: 0;
	}
	.indicatorRight {
		right: 0;
	}
}

.notificationCart {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: var(--yellow-200, #ffde07);
	position: absolute;
	right: 43%;
	top: 10px;
}
