@import "../../styles/variables.scss";
#video_top {
	position: absolute;
	left: 18px;
	top: 18px;
	width: calc(100% - 18px);
	z-index: 2;

	.image__container {
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.image__circle {
			border-radius: 50%;
			width: 51px;
			height: 51px;
			.image__logo {
				width: 45px;
				height: 45px;
				border-radius: 50%;
				border: 1.5px solid white;
				object-fit: cover;
			}
			&.live {
				border: 3px solid $red_error;
			}
		}
		.live__icon {
			display: flex;
			margin-top: -10px;
			.live-badge {
				font-family: $font_family;
				font-size: 8px;
				padding: 0px 6px;
				background-color: $red_error;
				color: #fff;
				font-weight: 700;
				border-radius: 2px;
				text-align: center;
			}
		}
	}

	.fira_live__container {
		background-color: rgba(1, 2, 2, 0.7);
		// margin-top: 10px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 5px 3px 5px;

		span {
			font-family: Montserrat, Arial;
			font-size: 8px;
			font-weight: 700;
			line-height: 9.75px;
		}

		.text-yellow {
			color: #ffde07;
		}
	}

	.views__container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 8px;
		background-color: rgba(1, 2, 2, 0.5);
		backdrop-filter: blur(6px);
		border-radius: 3px;

		span {
			padding: 2px 5px 0 5px;
			font-family: Montserrat, Arial;
			font-size: 10px;
			font-weight: 700;
			line-height: 12px;
			color: #fff;
		}
	}

	@media (max-width: 981px) {
		width: 64px;
	}
}
