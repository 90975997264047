.ChatWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 1fr auto;
	padding: 8px;
	box-sizing: border-box;
	z-index: 1000;
	background-color: #fff;
	border-radius: 8px;
	&.WithPinMessage {
		grid-template-rows: auto 1fr auto;
		@media (max-width: 981px) {
			grid-template-rows: 1fr auto auto;
		}
	}
	@media (max-width: 981px) {
		background-color: transparent;
	}
}

.RetryWrapper,
.DiconnectWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.IconWrapper {
		color: #e5e5e6;
		font-size: 62px;
		@media (max-width: 981px) {
			color: #a4a4a4;
		}
	}
	span {
		max-width: 200px;
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;
		color: #0e1621;
		@media (max-width: 981px) {
			color: #a4a4a4;
		}
	}
	button {
		position: absolute;
		bottom: 8px;
		left: 16px;
		right: 16px;
		box-sizing: border-box;

		border: none;
		padding: 12px 10px;
		border-radius: 4px;

		font-size: 12px;
		font-style: normal;
		font-weight: 600;
	}
}

@media (max-width: 981px) {
	.ChatWrapper {
		padding-bottom: 20px;
	}
}
