#sharePopUp {
	background-color: #010202b3;
	width: 500px;
	position: absolute;
	border-radius: 8px;
	bottom: 64px;
	right: 23px;
	padding: 16px;
	z-index: 10;

	.share-pop-up_container {
		display: grid;
		grid-template-rows: max-content 1fr;
		gap: 16px;

		.share-pop-up_header {
			display: grid;
			grid-template-columns: 1fr max-content;
		}

		.share-pop-up_logos {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: 15px;

			.share-pop-up_logo {
				display: flex;
				place-items: center;
				flex-direction: column;
				gap: 5px;
				cursor: pointer;
			}

			.gmail_wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 61px;
				height: 61px;
				background: #ffffff;
				border-radius: 50%;
			}

			.logo-title {
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				color: #ffffff;
			}
		}

		.share-pop-up_link_container {
			display: grid;
			grid-template-columns: 1fr max-content;
			gap: 8px;

			.share-pop-up_link_input {
				background-color: #fff;
				color: #010202;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				border: none;
				border-radius: 8px;
				text-overflow: ellipsis;
				padding: 12px 9px;
			}

			.share-pop-up_link_button {
				padding: 12px 9px 10px 9px;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				border-radius: 8px;
				border: none;
			}
		}

		.share-pop-up_content_title h5 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 18px;
			color: #ffffff;
		}
	}

	@media screen and (max-width: 981px) {
		right: 63px;
		width: max-content;
		max-width: calc(100% - 80px);

		.share-pop-up_container {
			.share-pop-up_logos {
				.gmail_wrapper {
					width: 32px;
					height: 32px;
				}
			}
		}
	}

	@media screen and (max-width: 420px) {
		.share-pop-up_container {
			.share-pop-up_logos {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
}
