@import "../../styles/variables.scss";

.products_wrapper {
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	gap: 8px;
	width: 100%;
	max-width: 100%;
	padding: 9px 15px;
	border: 1px solid #d5dde0;
	border-radius: 10px;
	background-color: #fff;
	z-index: 2;

	.glider-track {
		display: none;
	}

	#products {
		display: flex;
		gap: 8px;
		width: 100%;
		max-width: 100%;
		border-radius: 10px;
		padding: 0 5px;
	}

	.nextArrow,
	.prevArrow {
		width: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		cursor: pointer;
	}

	.prevArrow {
		transform: rotate(180deg);
	}

	@media (max-width: 981px) {
		grid-template-columns: 1fr;
		border-radius: 0;

		.nextArrow,
		.prevArrow {
			display: none;
		}
	}
}
