@import "../../styles/variables.scss";
#header {
	display: grid;
	grid-gap: 15px;
	align-items: center;
	box-sizing: border-box;
	border-radius: 10px;
	height: 56px;
	width: calc(100% - 18px);

	&.no_controls .info_container {
		justify-content: flex-start;
	}

	.info_container.details_container {
		position: absolute;
		top: 0;
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;
		height: auto;
		max-width: calc(100% - 30px);
	}

	.image__container {
		display: flex;
		align-items: center;
		flex-direction: column;
		.image__circle {
			border-radius: 50%;
			.image__logo {
				height: 45px;
				width: 45px;
				border-radius: 50%;
				border: 1.5px solid white;
				object-fit: cover;
			}
			&.live {
				border: 3px solid $red_error;
			}
		}

		.live__icon {
			display: flex;
			margin-top: -13px;
			.live-badge {
				font-family: $font_family;
				font-size: 10px;
				padding: 0px 10px;
				background-color: $red_error;
				color: #fff;
				font-weight: 700;
				border-radius: 2px;
				text-align: center;
			}
		}
	}

	.info_container.live_tag_container {
		position: absolute;
		top: 0;
		justify-content: flex-start;
		gap: 8px;
	}

	.info_container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.title_wrapper {
			height: 56px;
			display: flex;
			justify-content: end;
			flex-direction: column;
			gap: 10px;
			max-width: 100%;
			overflow: hidden;
		}

		.header_title {
			font-family: $header_title_font_family;
			font-size: $header_title_font_size;
			line-height: $header_title_line_height;
			font-weight: $header_title_font_weight;
			color: $header_title_color;
			margin-bottom: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-right: 30px;
		}

		.fira_live__container {
			width: 64px;
			background-color: rgba(1, 2, 2, 0.7);
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px 5px 3px 5px;

			span {
				font-family: Montserrat, Arial;
				font-size: 8px;
				font-weight: 700;
				line-height: 9.75px;
			}

			.text-yellow {
				color: #ffde07;
			}
		}

		.fira_live__container.live_tag {
			background-color: $red;
			margin-top: 0;
			padding: 0;
			height: 16px;

			.text-white {
				font-size: $live_tag_font_size;
				line-height: $live_tag_line_height;
			}
		}
	}

	.stats_container {
		height: 100%;
		width: auto;
		justify-self: end;

		.stats_element {
			background-color: rgba(1, 1, 2, 0.5);
			display: flex;
			align-items: center;
			height: 22px;
			border-radius: 3px;
			padding: 0 10px 0 5px;
			gap: 5px;

			.stats-text {
				font-family: $font_family;
				font-size: 10px;
				font-weight: 700;
				line-height: 12px;
				color: #fff;
			}
		}
	}
}
