.ProgressBarWrapper {
	gap: 14px;
	display: flex;
	flex-direction: column;
	.TimerWrapper {
		color: #fff;
		font-size: 14px;
		font-weight: 600;
	}
	.progressBar {
		position: relative;
		height: 5px;
		background-color: #e4e4e7;
		cursor: pointer;

		.progress-bar-inner {
			height: 100%;
		}

		.BarSeeker {
			position: absolute;
			top: -6px;
			width: 16px;
			border-radius: 50%;
			height: 16px;
			transform: translateX(-50%);
			cursor: pointer;
		}
	}
}
