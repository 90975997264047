@import "../../styles/variables.scss";

.SheetWrapper {
	width: 100%;
	background-color: #fff;
	border-radius: 16px 16px 0px 0px;
	position: absolute;
	left: 0;
	bottom: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	overflow-y: auto;
}

@media (max-height: 500px) {
	.SheetWrapper {
		height: calc(100vh - 123px);
	}
}
