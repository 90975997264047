@import url(./helpers.scss);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,700&display=swap");

body {
	background-color: transparent;
	height: 100vh;
	height: -webkit-fill-available;
}

#root {
	height: 100vh;
	height: -webkit-fill-available;
}

#app__wrapper {
	background-color: transparent;
	height: 100vh;
	height: -webkit-fill-available;
	.main_wrapper {
		display: grid;
		grid-template-rows: 1fr max-content;
		height: 100vh;
		height: -webkit-fill-available;
		gap: 8px;

		@media (max-width: 981px) {
			gap: 0;
		}
	}

	.app__container {
		display: grid;
		max-height: 100%;
		height: 100%;
		background-color: transparent;
		gap: 7px;

		.video-data__wrapper {
			display: grid;
			height: 100%;
			max-height: 100%;
			gap: 7px;

			@media screen and (max-width: 981px) {
				grid-template-rows: 1fr max-content;
				grid-gap: 0;
			}
		}

		@media screen and (max-width: 981px) {
			grid-gap: 0;
			grid-template-columns: 1fr;
		}
	}
}

.error__container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

#chat__wrapper {
	position: relative;
	height: 100%;
	max-height: 100%;
	background-color: #fff;
}

.spin-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background: transparent;
	animation: 1s linear infinite;
}

.input-check {
	cursor: pointer;
}

@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}

.spin::before {
	animation: 1.5s linear infinite spinner;
	animation-play-state: inherit;
	border: solid 5px #cfd0d1;
	border-bottom-color: #ffde07;
	border-radius: 50%;
	content: "";
	height: 40px;
	width: 40px;
	position: absolute;
	transform: translate3d(-50%, -50%, 0);
	will-change: transform;
}

html {
	height: -webkit-fill-available;
}
