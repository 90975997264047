@import "../../styles/variables.scss";

#Player {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	border-radius: 10px;
	position: relative;

	.shadow-top {
		position: absolute;
		border-radius: 10px;
		top: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 79.69%);
		z-index: 1;
		opacity: 0.2;
		transform: rotate(-180deg);
	}

	.shadow-bottom {
		position: absolute;
		border-radius: 10px;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 79.69%);
		z-index: 1;
		opacity: 0.2;
	}

	.subscriberContainer {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		#fira-subscriber {
			position: absolute;
			height: 100%;
			width: 100%;
			max-width: 100%;
			border-radius: 6px;

			@media screen and (min-width: 981px) {
				object-fit: contain;
			}
		}
	}

	.reconnect_popup_wrapper {
		position: absolute;

		.message_popup__content {
			background-color: #fff;

			.message_popup__content__title {
				color: #010202;
			}

			.message_popup__content__text {
				color: #010202;
			}
		}
	}

	@media screen and (max-width: 981px) {
		border-radius: 0;
		margin: 0;

		.darkBg {
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 1) 79.69%
			);
			position: absolute;
			height: 300px;
			width: 100%;
			bottom: 0;
			opacity: 0.3;
		}
	}

	.eventPreview {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		> img {
			height: 100vh;
			max-width: 100%;
			object-fit: contain;
		}
	}
}

.noVideoText {
	color: #fff;
	text-align: center;
}

.progress-control-mobile {
	z-index: 100;
	position: absolute;
	bottom: 29px;
	right: 24px;
	left: 24px;
	> * {
		width: 100%;
	}
}

.videoPaused {
	position: absolute;
	z-index: 15;
	display: grid;
	gap: 32px;
	grid-template-columns: max-content max-content max-content;
	align-items: center;

	svg {
		cursor: pointer;
	}

	@media screen and (max-width: 981px) {
		width: 100%;
		height: 100%;
		justify-content: center;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.8) 79.69%
		);
	}
}

.videoMuted {
	border-radius: 32.5px;
	position: absolute;

	img {
		width: 9rem;
	}
}

//Product Pop Up
.product-pop-up {
	position: absolute;
	z-index: $product_popup_z_index_desktop;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

div#player_media_wrapper,
#fira_media_player,
video {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	position: absolute;
}
