.BouncingLoader {
	display: flex;
	justify-content: center;
}

.BouncingLoader > div {
	width: 16px;
	height: 16px;
	margin: 3px 6px;
	border-radius: 5px;
	animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
	to {
		transform: translateY(-16px);
	}
}

.BouncingLoader > div:nth-child(2) {
	animation-delay: 0.2s;
}

.BouncingLoader > div:nth-child(3) {
	animation-delay: 0.4s;
}
