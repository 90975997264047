@import "../../styles/variables.scss";

#ChatDialog {
	display: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(100% - 64px);
	height: 60%;
	background-color: transparent;
	border-radius: 10px;
	z-index: $product_popup_z_index_mobile;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 150%);
	@media screen and (max-height: 450px) {
		height: 80%;
	}
}
